<template>
  <validation-observer ref="simpleRules">
    <!-- <this-header /> -->
    <div class="anan-set-nav-conttainer2 mt-150">
      <div>
        <router-link :to="{ name: 'new-pages' }">
          <b-button class="btn btn-gradient-primary mb-1 mr-50 mr-50 col-md-3 col-lg-2 col-sm-12">
            {{ $t('key-126') }}
          </b-button>
        </router-link>
        <div class="card">
          <div class="section-title pl-2 pt-2 pr-2">
            <span class="font-weight-bolder">{{ $t("parcelCode") }}</span>

            <div class="d-flex">
              <div class="income-order-search order-search w-100">
                <div class="anan-input search-input">
                  <div class="anan-input__inner anan-input__inner--normal">
                    <input
                      v-model="search"
                      type="text"
                      :placeholder="$t('search')"
                      class="anan-input__input"
                      @input="getTracking()"
                    >
                    <div class="anan-input__suffix">
                      <i class="anan-input__clear-btn anan-icon" />
                      <i class="anan-input__suffix-icon anan-icon">
                        <i class="fal fa-search" />
                      </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cols-3">
            <router-link
              :to="{ name: 'tracking-create' }"
              class="px-2"
            >
              <b-button class="btn btn-gradient-primary mb-1">
                + {{ $t('addTrackingCode') }}
              </b-button>
            </router-link>
          </div>

          <!-- <button
              type="button"
              class="anan-button--xl-large anan-button anan-button--primary anan-button--normal w-50"
            >
              <span>+ {{ $t('addTrackingCode') }} </span>
            </button> -->

          <!-- </router-link> -->
          <b-overlay
            :show="showOver"
            variant="transparent"
            opacity="0.99"
            blur="5px"
            rounded="sm"
          >
            <template #overlay>
              <div class="text-center">
                <p id="cancel-label">
                  กรุณารอสักครู่...
                </p>
              </div>
            </template>
            <div class="pl-2 pr-2 pt-1">
              <b-table
                striped
                hover
                responsive
                :per-page="perPage"
                :items="itemarr"
                :fields="fields"
              >
                <template #cell(detail)="data">
                  <div style="max-width: 300px !important;">

                    <div class="d-flex">
                      <div class="w-50">
                        <p class="mb-0 text-right mr-50">
                          {{ $t("key-22") }} :
                        </p>
                      </div>
                      <div class="w-50">
                        <small class="text-black">
                          {{ data.item.tag_id ? data.item.tag_id : "-" }}
                        </small>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="w-50">
                        <p class="mb-0 text-right mr-50">
                          {{ $t("systemOrder") }} :
                        </p>
                      </div>
                      <div class="w-50">
                        <b-link
                          v-if="data.item.order_text"
                          :to="{
                            name: 'order-detail',
                            params: { id: data.item.order_id },
                          }"
                          class="mb-0"
                        >
                          ORD-{{data.item.order_text}}
                        </b-link>
                        <small v-else>-</small>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="w-50">
                        <p class="mb-0 text-right mr-50">
                          {{ $t("parcelCode") }} :
                        </p>
                      </div>
                      <div class="w-50">
                        <b-link
                          :to="{
                            name: 'tracking-edit',
                            params: { id: data.item._id },
                          }"
                          class="mb-0"
                        >
                          {{ data.item.postnumber }}
                        </b-link>
                        <i
                          v-clipboard:copy="data.item.postnumber"
                          :class="iconClass[data.item.postnumber]
                            ? 'fal fa-clipboard-check text-success'
                            : 'fal fa-copy text-primary'
                          "
                          @click="handleCopy(data.item.postnumber)"
                        />
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="w-50">
                        <p class="mb-0 text-right mr-50">
                          {{ $t("lotSequence") }} :
                        </p>
                      </div>
                      <div class="w-50">
                        <p
                          class="mb-0 text-black badge badge-warning"
                          :class="{
                            'mb-0 text-black badge badge-danger':
                              data.item.transportType === 2,
                          }"
                        >
                          {{ checktype(data.item.transportType) }}
                        </p>
                        <!-- <b-link :to="{ name: 'asdsad' }"> -->
                        {{ data.item.lot_name ? data.item.lot_name : "-" }}
                        <!-- </b-link> -->
                        <small>/{{
                          data.item.lot_order ? Commas(data.item.lot_order) : "-"
                        }}</small>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="w-50">
                        <p class="mb-0 text-right mr-50">
                          {{ $t('woodPacking') }} :
                        </p>
                      </div>
                      <div class="w-50">
                        <span
                          v-if="data.item.packbox"
                          style="font-size: 11px;"
                          class="badge badge-success"
                        >{{ $t('activate') }}</span>
                        <span
                          v-else
                          style="font-size: 11px;"
                          class="badge badge-secondary"
                        >{{ $t('key-328') }}</span>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="w-50">
                        <p class="mb-0 text-right mr-50">
                          {{ $t('checkOrder') }} :
                        </p>
                      </div>
                      <div class="w-50">
                        <span
                          v-if="data.item.checkproduct"
                          style="font-size: 11px;"
                          class="badge badge-success"
                        >{{ $t('activate') }}</span>
                        <span
                          v-else
                          style="font-size: 11px;"
                          class="badge badge-secondary"
                        >{{ $t('key-328') }}</span>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="w-50">
                        <p class="mb-0 text-right mr-50">
                          {{ $t("type") }} :
                        </p>
                      </div>
                      <div class="w-50">
                        <p class="mb-0">
                          {{ checkproduct_type(data.item.product_type) }}
                        </p>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="w-50">
                        <p
                          class="mb-0 text-right mr-50"
                        >
                          {{ $t("mnemonic") }} :
                        </p>
                      </div>
                      <div class="w-50">
                        <p
                          class="mb-0 text-truncate text-left"
                          style="max-width: 200px !important"
                          data-toggle="tooltip"
                          data-placement="top"
                          :title="data.item.keyword ? data.item.keyword : '-'"
                        >
                          {{ data.item.keyword ? data.item.keyword : "-" }}
                        </p>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="w-50">
                        <p class="mb-0 text-right mr-50">
                          {{ $t("remarks") }} :
                        </p>
                      </div>
                      <div class="w-50">
                        <p class="mb-0 text-truncate text-left" style="max-width: 200px !important">
                          {{ data.item.remark ? data.item.remark : "-" }}
                        </p>
                      </div>
                    </div>
                    <div
                      v-if="data.item.claim_status"
                      class="d-flex text-danger border-top mt-25 pt-25"
                    >
                      <div class="w-50">
                        <p class="mb-0 text-right mr-50">
                          {{ $t('key-23') }} :
                        </p>
                      </div>
                      <div class="w-50">
                        <p class="mb-0">
                          {{ data.item.claim_detail ? data.item.claim_detail: '-' }}
                        </p>
                        <p class="mb-0">
                          {{ $t('quantity') }} : {{ data.item.claim_amount ? Commas(data.item.claim_amount): '0' }} ฿
                        </p>
                      </div>
                    </div>
                  </div>
                </template>

                <template #cell(calPrice)="data">
                  <div class="d-flex">
                    <div class="w-50">
                      <div class="mb-0 text-right mr-50">
                        <small>{{ $t("dimensionsCm") }} :</small>
                      </div>
                    </div>
                    <div class="w-50">
                      <small class="mb-0">
                        ({{ Commas(data.item.width) }} x {{ Commas(data.item.long) }} x
                        {{ Commas(data.item.height) }}) x {{ Commas(data.item.product_amount) }}
                      </small>
                    </div>
                  </div>

                  <div class="d-flex">
                    <div class="w-50">
                      <div class="mb-0 text-right mr-50">
                        <small>{{ $t("weightKilo") }} :</small>
                      </div>
                    </div>
                    <div class="w-50">
                      <small class="mb-0">
                        ({{ Commas(data.item.weight) }}) x
                        {{ Commas(data.item.product_amount) }}
                      </small>
                    </div>
                  </div>

                  <div class="d-flex">
                    <div class="w-50">
                      <div class="mb-0 text-right mr-50">
                        <small>{{ $t("quantity") }} :</small>
                      </div>
                    </div>
                    <div class="w-50">
                      <small class="mb-0">
                        {{ Commas(data.item.product_amount) }}
                      </small>
                    </div>
                  </div>

                  <div class="d-flex">
                    <div class="w-50">
                      <div class="mb-0 text-right mr-50">
                        <small>{{ checkcharge_per(data.item) }} :</small>
                      </div>
                    </div>
                    <div class="w-50">
                      <p class="mb-0">
                        {{ Commas(data.item.charge_per_save) }} ฿
                      </p>
                    </div>
                  </div>
                  <div class="mt-50">
                    <div
                      v-if="data.item.path_img.length > 0"
                      class="d-flex flex-wrap justify-content-center"
                    >
                      <div
                        v-for="url in data.item.path_img"
                        :key="url"
                        class="preview-image-container mr-25 d-flex justify-content-center"
                      >
                        <img
                          :src="GetImg('postnumber_img',url)"
                          alt="Preview"
                          class=""
                          style="max-width: 100%; height: auto;"
                          @click="showPicture('postnumber_img',data.item.path_img)"
                        >
                      </div>
                    </div>
                  </div>
                </template>

                <template #cell(tranStatus)="data">
                  <app-timeline>
                    <!-- <app-timeline-item
                    v-if="data.item"
                    :title="`: นำออกแล้ว`"
                    variant="success"
                  /> -->
                    <app-timeline-item
                      v-if="data.item.paymentday"
                      :title="`${data.item.paymentday} :` + $t('payShipping')"
                      variant="success"
                    />
                    <app-timeline-item
                      v-if="data.item.out_thaiwarehouse"
                      :title="`${data.item.out_thaiwarehouse}: ` +
                        $t('arrivedThailand')
                      "
                      variant="success"
                    />

                    <app-timeline-item
                      v-if="data.item.come_thaiwarehouse"
                      :title="`${data.item.come_thaiwarehouse}: ` +
                        $t('arrivedThaiWarehouse')
                      "
                      variant="success"
                    />

                    <app-timeline-item
                      v-if="data.item.out_chinesewarehouse"
                      :title="`${data.item.out_chinesewarehouse}: ` +
                        $t('exitWarehouse')
                      "
                      variant="success"
                    />

                    <app-timeline-item
                      v-if="data.item.come_chinesewarehouse"
                      :title="`${data.item.come_chinesewarehouse} : ` +
                        $t('arrivedChinaWarehouse')
                      "
                      variant="success"
                    />
                  </app-timeline>
                </template>

                <template #cell(bill)="data">
                  {{
                    data.item.itemnumber_payment
                      ? data.item.itemnumber_payment
                      : $t("none")
                  }}
                  <!-- <b-link
                  :to="{name : 'admin-tracking-edit',}"
                  class="mb-0"
                >
                  'ไม่มี'
                </b-link> -->
                </template>

                <template #cell(action)="data">
                  <feather-icon
                    icon="Edit3Icon"
                    class="mr-50 text-primary cursor-pointer"
                    @click="EditType(data.item)"
                  />

                  <feather-icon
                    v-if="!data.item.come_chinesewarehouse && !data.item.out_chinesewarehouse && !data.item.come_thaiwarehouse"
                    icon="TrashIcon"
                    class="text-danger cursor-pointer"
                    @click="DeleteData(data.item)"
                  />
                </template>
                <template #cell(Status)="data">
                  
                  <span
                    v-if="data.item.check_postnumber"
                    style="font-size: 11px;"
                    class="badge badge-success"
                  >{{ $t('key-330') }}</span>
                  <span
                    v-else
                    style="font-size: 11px;"
                    class="badge badge-danger"
                  >{{ $t('key-329') }}</span>
                </template>
              </b-table>

              <div class="demo-spacing-0 d-flex justify-content-end mb-2">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                  @input="getTracking()"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </b-overlay>
        </div>

        <b-modal
          id="modal-add-new"
          :title="$t('key-21')"
          :ok-title="$t('save')"
          :cancel-title="$t('cancel')"
          @ok.prevent="submitAddNew()"
        >
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Customer Code"
              rules="required"
            >
              <label for="customerCode">{{ $t("customerCode") }} :
                <span class="text-danger">*</span></label>
              <b-form-input
                id="customerCode"
                v-model="customerCode"
                :state="errors.length > 0 ? false : null"
                type="text"
                :placeholder="$t('customerCode')"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Type"
              rules="required"
            >
              <label for="type">{{ $t("type") }} : <span class="text-danger">*</span></label>
              <b-form-select
                v-model="type"
                :options="typeOp"
                :state="errors.length > 0 ? false : null"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="detail">{{ $t("details") }} :</label>
            <b-form-textarea
              id="detail"
              v-model="detail"
              :placeholder="$t('details')"
            />
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Amount"
              rules="required"
            >
              <label for="amount">{{ $t("quantity") }} :
                <span class="text-danger">*</span></label>
              <b-form-input
                id="amount"
                v-model="amount"
                type="number"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('quantity')"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="prefix">Prefix :</label>
            <b-form-input
              id="prefix"
              v-model="prefix"
              type="number"
              placeholder="Prefix"
            />
          </b-form-group>

          <b-form-group>
            <label for="prefixNum">Prefix Number :</label>
            <b-form-input
              id="prefixNum"
              v-model="prefixNum"
              type="number"
              placeholder="Prefix Number"
            />
          </b-form-group>
        </b-modal>
      </div>
    </div>
    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
    />
  </validation-observer>
</template>

<script>
import {
  BTable,
  BLink,
  BFormSelect,
  BModal,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  components: {
    BTable,
    BLink,
    BFormSelect,
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BPagination,
    AppTimeline,
    AppTimelineItem,
    // ThisHeader,
    BOverlay,
    VueImageLightboxCarousel,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      message: '78708961999066',
      totalRows: 0,
      isActive: 'all',
      required,
      selected: null,
      amount: 0,
      detail: '',
      type: null,
      customerCode: '',
      prefix: '',
      prefixNum: '',
      currentPage: 1,
      perPage: 10,
      search: null,
      imagePreviewUrls: [],

      itemarr: [],
      itemss: [
        {
          id: 1,
          detail: {
            name: 'รายการ 1',
            price: 100,
            amount: 1,
          },
          customerCode: 'karayme68',
          calPrice: 'คำนวณราคา',
          tranStatus: {
            cargoCn: '21/07/2023',
            exportCn: '22/07/2023',
            cargoTh: '27/07/2023',
            payFreight: '28/07/2023',
            removed: 'PAY-6918',
          },
          setup: 'P42',
          bill: 'PAY-6918',
          action: 'จัดการ',
        },

        {
          id: 2,
          detail: {
            name: 'รายการ 1',
            price: 100,
            amount: 1,
          },
          customerCode: 'manit2708',
          calPrice: 'คำนวณราคา',
          tranStatus: {
            cargoCn: '21/07/2023',
            exportCn: '22/07/2023',
            cargoTh: '27/07/2023',
            payFreight: '',
            removed: '',
          },
          setup: 'P42',
          bill: '',
          action: 'จัดการ',
        },

        {
          id: 3,
          detail: {
            name: 'รายการ 1',
            price: 100,
            amount: 1,
          },
          customerCode: 'boycott01',
          calPrice: 'คำนวณราคา',
          tranStatus: {
            cargoCn: '21/07/2023',
            exportCn: '',
            cargoTh: '',
            payFreight: '',
            removed: '',
          },
          setup: 'P42',
          bill: 'PAY-6918',
          action: 'จัดการ',
        },
      ],
      iconClass: {},
      showOver: false,
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'detail',
          label: this.$t('details'),
          thStyle: { width: '25%' },
          thClass: 'text-center',
        },
        {
          key: 'calPrice',
          label: this.$t('calculatePrice'),
          sortable: false,
          thStyle: { width: '20%' },
          thClass: 'text-center',
        },
        {
          key: 'tranStatus',
          label: this.$t('shippingStatus'),
          sortable: false,
          thStyle: { width: '20%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'bill',
          label: this.$t('issueBill'),
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'Status',
          label: this.$t('status'),
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'action',
          label: this.$t('manage'),
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    this.getTracking()
    // this.getData('all')
  },
  methods: {
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    showPicture(pathfile, picture) {
      this.showLightbox = true
      // console.log(picture)
      // eslint-disable-next-line no-shadow
      picture.forEach(picture => {
        const img = { path: this.GetImg(pathfile, picture) }
        this.images.push(img)
      })
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    Claim() {
      this.$swal({
        title: '<h3 style="color: #141414">ทำรายการ Claim สินค้า</h3>',
        icon: 'warning',
        showCancelButton: true,
        text: 'คุณต้องเคลมสินค้าหรือไม่ ?',
        confirmButtonText: 'Yes, claim it!',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: '<h3 style="color: #141414">สำเร็จ</h3>',
            html: '<p style="color: #141414">ทำรายการเคลมสินค้าสำเร็จ</p>',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
    },
    async getTracking() {
      this.showOver = true
      try {
        const params = {
          search: this.search,
          page: this.currentPage,
          perPage: this.perPage,
        }
        const { data: res } = await this.$http.get('/Tracking/getcustomer', { params })
        this.itemarr = res.data
        this.totalRows = res.total
        this.showOver = false
        // console.log(this.itemarr)

        // this.itemss = res
      } catch (e) {
        console.log(e)
        this.showOver = false
      }
    },
    checktype(item) {
      if (item === 1) {
        return this.$t('truck')
      }
      if (item === 2) {
        return this.$t('ship')
      }
      return ''
    },
    checkproduct_type(item) {
      if (item) {
        if (this.$i18n.locale === 'th') {
          return item.pro_name
        } if (this.$i18n.locale === 'en') {
          return item.pro_name_en
        } if (this.$i18n.locale === 'cn') {
          return item.pro_name_cn
        }
      } else {
        return '-'
      }
    },
    checkcharge_per(item) {
      if (item.charge_per_save === item.charge_per_kilo) {
        return this.$t('calculateByKilo')
      }
      return this.$t('key-98')
    },
    removeImage(url) {
      this.imagePreviewUrls = this.imagePreviewUrls.filter(
        item => item !== url,
      )
    },
    submitAddNew() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const params = {
            customerCode: this.customerCode,
            type: this.type,
            detail: this.detail,
            amount: this.amount,
            prefix: this.prefix,
            prefixNum: this.prefixNum,
          }
          this.$http.post('groupbyuser/list/create', params).then(() => {
            this.getData(this.isActive)
            this.$bvToast.toast('เพิ่มข้อมูลสำเร็จ', {
              title: 'สำเร็จ',
              variant: 'success',
              solid: true,
              autoHideDelay: 1500,
            })
          })
          this.$bvModal.hide('modal-add-new')
        }
      })
    },
    getDataStorage() {
      const data = JSON.parse(localStorage.getItem('data'))
      this.imagePreviewUrl = data.imagePreviewUrl
      this.imagePreviewUrls = data.imagePreviewUrls
      this.colorBG = data.colorBG
      this.colorText = data.colorText
      this.gradientColor = data.gradientColor
    },
    handleFileChanges(event) {
      const { files } = event.target
      this.imagePreviewUrls = []
      console.log(this.imagePreviewUrls)
      //   add to local storage
      localStorage.setItem(
        'imagePreviewUrls',
        JSON.stringify(this.imagePreviewUrls[0]),
      )

      for (let i = 0; i < files.length; i += 1) {
        const reader = new FileReader()
        reader.onload = e => {
          this.imagePreviewUrls.push(e.target.result)
        }
        reader.readAsDataURL(files[i])
      }
    },
    addMoreImages() {
      this.$refs.fileInput.click()
      console.log(this.imagePreviewUrls)
    },
    handleFileChange(event) {
      const file = event.target.files[0]
      this.imgBackground = file
      this.previewImage(file)
    },
    previewImage(file) {
      const reader = new FileReader()
      reader.onload = () => {
        this.imagePreviewUrl = reader.result
      }
      reader.readAsDataURL(file)
    },
    showAddNew() {
      this.$bvModal.show('modal-add-new')
    },
    // async getData(type) {
    //   this.isActive = type
    //   try {
    //     const { data: res } = await this.$http.get('/groupbyuser/list/index')
    //     this.items = res
    //   } catch (e) {
    //     console.log(e)
    //   }
    // },
    EditType(data) {
      // eslint-disable-next-line no-underscore-dangle
      this.$router.push({ name: 'tracking-edit', params: { id: data._id } })
    },
    DeleteData(data) {
      this.$bvModal
        .msgBoxConfirm('คุณต้องการลบข้อมูลใช่หรือไม่?', {
          title: 'ยืนยันการลบข้อมูล',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            const params = {
              // eslint-disable-next-line no-underscore-dangle
              id: data._id,
            }
            this.$http.post('/Tracking/removePostnumber', params).then(() => {
              this.getTracking()
              this.$bvToast.toast('ลบข้อมูลสำเร็จ', {
                title: 'สำเร็จ',
                variant: 'success',
                solid: true,
                autoHideDelay: 1500,
              })
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleCopy(item) {
      if (item) {
        this.$set(this.iconClass, item, true)

        setTimeout(() => {
          this.$set(this.iconClass, item, false)
        }, 3500)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
